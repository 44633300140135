<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:w-3/5 space-y-7">
      <div class="card">
        <img :alt="playlist.title"
             :src="playlist.thumbnail ? playlist.thumbnail.url : 'https://static.bcdcnt.net/assets/images/bcdcnt-bg.png'"
             class="object-cover"/>

        <div class="p-3 md:flex items-center justify-between">
          <div>
            <h1 class="text-2xl font-semibold">{{ playlist.title }}</h1>
            <div class="md:flex items-center justify-between" v-if="playlist.user">
              <router-link :title="playlist.user.username" :to="{name: 'UserDetail', params: {id: playlist.user.id}}"
                           class="flex items-center space-x-3">
                <img
                    :src="playlist.user.avatar ? playlist.user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                    :alt="playlist.user.username" class="w-10 rounded-full">
                <div class="">
                  <div class="text-base font-semibold">{{ playlist.user.username }}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-3">
        <div class="flex justify-content-between">
          <div>
            <div class="flex flex-col items-center"
                 :uk-tooltip="'title: ' + thousandFormat(playlist.items.data.length) + ' bài'">
              <span><i class="uil uil-music"></i></span>
              <span>{{ playlist.items.data.length }} bài hát</span>
            </div>
          </div>
          <div>
            <div class="flex flex-col items-center"
                 :uk-tooltip="'title: ' + thousandFormat(shareCount) + ' lượt chia sẻ'">
              <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + url" target="_blank"><i
                  class="uil uil-share-alt"></i></a>
              <span>{{ shareCount }} chia sẻ</span>
            </div>
          </div>
          <div>
            <div class="flex flex-col items-center"
                 :uk-tooltip="'title: ' + thousandFormat(playlist.views) + ' lượt nghe'">
              <span><i class="uil uil-headphones"></i></span>
              <span>{{ numberFormat(playlist.views) }} lượt nghe</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-3 mt-4">
        <div class="grid divide-gray-200 gap-x-4 mt-4 playlist-frame"
             v-if="playlist.items && playlist.items.data && playlist.items.data.length">
          <template v-for="(song, index) in playlist.items.data" :key="'song' + index">
            <song-item-playlist :song-index="songIndex" v-if="song.object" v-on:playItem="handlePlayItem"
                                :song="song.object" :index="index"/>
          </template>
        </div>
      </div>

      <div class="mt-4">
        <comment object-type="playlist" :comment-id="commentId" :object-id="id" field="id" type="ID"/>
      </div>
    </div>

    <div class="lg:w-2/5 w-full">
      <div class="card p-3">
        <latest-comment/>
      </div>

      <right/>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {
  changePageTitle, changeMetaTag,
  getPrefixFromSongType,
  logActivity,
  numberFormat,
  thousandFormat,
  timeago
} from "../../core/services/utils.service";
import SongItemPlaylist from "../SongItemPlaylist";
import Right from "../right/Right";
import Comment from "../comment/Comment";
import LatestComment from "../comment/LatestComment";

export default {
  name: "PlaylistDetail",
  components: {
    LatestComment,
    SongItemPlaylist,
    Right,
    Comment
  },
  data() {
    return {
      id: null,
      commentId: null,
      playlist: {
        title: "",
        views: 0,
        items: {
          data: []
        }
      },
      url: "",
      image: "https://static.bcdcnt.net/assets/images/bcdcnt-bg.png",
      shareCount: 0,
      songIndex: 0
    }
  },
  created() {
    window.addEventListener("message", (event) => {
      if (event.data.action === "songIndex") {
        this.songIndex = event.data.value;
      }
    }, false);
  },
  methods: {
    loadPlaylist() {
      let query = `query($id: ID!) {
        playlist(id: $id) {
          id
          title
          views
          user {
            id
            username
            avatar {
              url
            }
          }
          thumbnail {
            url
          }
          items(first: 1000, orderBy: [{column: "position", order: ASC}]) {
            data {
              object {
                __typename
                id
                title
                slug
                views
                file_type
                thumbnail {
                  url
                }
                file {
                  audio_url
                }
                ... on Song {
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Folk {
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Instrumental {
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Poem {
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Karaoke {
                  user {
                    id
                    username
                    avatar {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.playlist) {
              this.playlist = data.data.playlist;
              if (this.playlist.items && this.playlist.items.data && this.playlist.items.data.length) {
                let items = [];
                for (let i = 0; i < this.playlist.items.data.length; i++) {
                  if (this.playlist.items.data[i].object && this.playlist.items.data[i].object["__typename"]) {
                    let type = this.playlist.items.data[i].object["__typename"].toLowerCase();
                    this.playlist.items.data[i].object.prefix = getPrefixFromSongType(type);
                    items.push(this.playlist.items.data[i].object);
                  }
                }
                this.$emit("playPlaylist", items, "playlist");
              }
              this.url = window.location.href;
              this.image = this.playlist.thumbnail ? this.playlist.thumbnail.url : this.image;
              changePageTitle(this.playlist.title);
              changeMetaTag("description", this.playlist.content);
              changeMetaTag("title", this.playlist.title);
              changeMetaTag("image", this.image);
              changeMetaTag("url", this.url);
              changeMetaTag("audio", "");
              changeMetaTag("video", "");
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
    timeago(x) {
      return timeago(x);
    },
    handlePlayItem(index) {
      this.$emit("playItem", index);
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.commentId = this.$route.query.comment_id;
    this.loadPlaylist();
    logActivity("listen", "playlist", this.id);
  }
}
</script>
